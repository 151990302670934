.mobile {
  .form {
    background: none;
  }

  footer a {
    color: white;
  }

  // Mobile
  .mobile-login {
    // background-color: #222;
    height: 550px;
    position: relative;
    overflow: hidden;

    .content {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &::after {
      content: '';
      opacity: .8;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .login {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      width: 100%;
      z-index: 5;
      -webkit-transition: all .3s ease;

      .content {
        opacity: 1;
      }

      &.slide-up .content {
        opacity: 0;
        display: none !important;
      }

      &.slide-up {
        top: 5%;
        -webkit-transform: translate(-50%, 0%);
        -webkit-transition: all .3s ease;
      }

      &.slide-up .form-title {
        font-size: 1.6em;
        cursor: pointer;
      }

      &.slide-up .form-title span {
        margin-right: 10px;
        font-size: .8em;
        opacity: 1;
        display: inline;
        -webkit-transition: all .3s ease;
      }

      .form-title {
        span {
          color: rgba(0, 0, 0, 0.4);
          opacity: 0;
          display: none;
          -webkit-transition: all .3s ease;
        }
      }

      .submit-btn {
        background-color: rgba(0, 0, 0, 0.4);
        color: rgba(256, 256, 256, 0.7);
        border: 0;
        border-radius: 15px;
        display: block;
        margin: 15px auto;
        padding: 15px 45px;
        width: 100%;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all .3s ease;

        &:hover {
          transition: all .3s ease;
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }

    .signup {
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      color: white;
      -webkit-transition: all .3s ease;
      background: $color2;
      background: -webkit-linear-gradient(to right, $color1, $color2);
      background: linear-gradient(to right, $color1, $color2);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0;

      .content {
        opacity: 1;
      }

      &.slide-up .content {
        opacity: 0;
        display: none !important;
      }

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: -50px;
        -webkit-transform: translate(-50%, 0);
        width: 240%;
        height: 620px;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        z-index: 4;
        -webkit-transition: all .3s ease;
        background: $color2;
        background: -webkit-linear-gradient(to right, $color1, $color2);
        background: linear-gradient(to right, $color1, $color2);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
      }

      .form {
        position: absolute;
        top: calc(50% - 10%);
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        width: 65%;
        z-index: 5;
        -webkit-transition: all .3s ease;
        margin-top: -20px;

        .form-title {
          span {
            color: rgba(0, 0, 0, 0.4);
            opacity: 0;
            display: none;
            -webkit-transition: all .3s ease;
          }
        }

        button {
          background-color: transparent;
          border-color: #FFFFFF;
        }
      }

      &.slide-up {
        top: 110%;
        -webkit-transition: all .3s ease;

        &::before {
          top: -170px;
        }
      }

      &.slide-up .form {
        top: 10%;
        margin-top: -120px;
        -webkit-transform: translate(-50%, 0%);
        -webkit-transition: all .3s ease;
      }

      &.slide-up .form-title {
        margin: 0;
        padding: 0;
        cursor: pointer;
        font-size: 1.6em;
        -webkit-transition: all .3s ease;
      }

      @media (max-width: 320px) {
        &.slide-up .form-title {
          font-size: 1.4em;
        }
      }

      &.slide-up .form-title span {
        margin-right: 10px;
        font-size: .8em;
        opacity: 1;
        display: inline;
        -webkit-transition: all .3s ease;
      }
    }

    .form {
      width: 65%;
      margin: auto;
    }
  }

  .wizard {
    .step {
      z-index: 5;

      &.out,
      &.next {
        z-index: 5;
      }
      
      &.active {
        z-index: 10;
      }
    }
  }
}

@media (max-width: 768px) {
  body {
    background-color: white;
  }
}

@media (min-width: 768px) {
  button.ghost.prev {
    background-color: rgba(0, 0, 0, 0.22);
  }
}

@media (min-width: 426px) {
  .form {
    padding: 0 50px;
  }
}
