.wizard {
  flex: 1;
  display: flex;
  position: relative;
  white-space: nowrap;
  transition: all 0.4s;
  background-color: white;

  .content, .form-title {
    white-space: normal;
  }

  .step {
    opacity: 0;
    min-width:100%;

    &.out {
      opacity: 0;
      transition:all 0.5s;
    }

    &.next {
      opacity: 0;
      transition:all 0.5s;
    }

    &.active {
      opacity:1;
      transition:all 0.5s;
    }

    .input,
    .message,
    .MuiInputAdornment-root,
    .MuiFormControl-root {
      opacity: 0;
      transition: all 0.5s;
      transition-delay: 0s;
    }
  }

  @for $i from 0 through 3 {
    .step:nth-of-type(#{$i + 1}) {
      .input,
      .message,
      .MuiInputAdornment-root,
      .MuiFormControl-root {
        transform: translateX(100% * $i + 100%) scale(0.5);
      }

      &.out {
        transform: translateX(-100% * $i + -100%);

        .input,
        .message,
        .MuiInputAdornment-root,
        .MuiFormControl-root {
          transform: translateX(-100% * $i + -100%) scale(0.5);
        }
      }
      
      &.next {
        transform: translateX(-100% * $i + 100%);
      }

      &.active {
        transform: translateX(-100% * $i);

        .input,
        .message,
        .MuiInputAdornment-root,
        .MuiFormControl-root {
          opacity: 1;
          transform: translateX(0%);
          transition: all 0.5s;

          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              transition-delay: 0.2s*$i
            }
          }
        }
      }
    }
  }
}